<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <div class="col-lg-12 text-right">
                    <v-btn class="" color="red ma-1" elevation="2" @click="$router.go(-1)" dark>BACK</v-btn><v-btn class="ma-1" color="primary" elevation="2" @click="PrintSP()" dark><v-icon dark>mdi-printer</v-icon></v-btn>
                </div>
                <div id="divPrint">
                    
                    <div class="text-center">
                        <img src="https://kencana.org/images/Logo-Kencana.png" alt="My Image" style="width: 50%;">
                    </div>
                    <div class="text-center"><b><h4>SURAT PESANAN</h4></b></div>
                    
                    <v-simple-table>
                        <tr>
                            <td width="100px" style="font-size: 8px;">TANGGAL</td>
                            <td style="font-weight: bold; font-size: 8px;">: {{ printTanggalModel ? printTanggalModel : '-' }}</td>
                            <td width="100px" style="font-size: 8px;">NIK / NPWP</td>
                            <td style="font-weight: bold; font-size: 8px;">: {{ printNIKModel ? printNIKModel : '-' }}</td>
                            
                        </tr>
                        <tr>
                            <td width="100px" style="font-size: 8px;">CUSTOMER</td>
                            <td style="font-weight: bold; font-size: 8px;">: {{ printCustomerModel ? printCustomerModel : '-' }}</td>
                            <td width="100px" style="font-size: 8px;">PEMBAYARAN</td>
                            <td style="font-weight: bold; font-size: 8px;">: {{ printPembayaranModel ? printPembayaranModel : '-' }}</td>
                        </tr>
                        <tr>
                            <td width="100px" style="font-size: 8px;">SALES</td>
                            <td style="font-weight: bold; font-size: 8px;">: {{ printSalesModel ? printSalesModel : '-' }}</td>
                            <td width="100px" style="font-size: 8px;">PENGIRIMAN</td>
                            <td style="font-weight: bold; font-size: 8px;">: {{ printPengirimanModel ? printPengirimanModel : '-' }}</td>
                        </tr>
                        <tr>
                            <td width="100px" style="font-size: 8px;">ALAMAT</td>
                            <td style="font-weight: bold; font-size: 8px;">: {{ printAlamatModel ? printAlamatModel : '-' }}</td>
                        </tr>
                    </v-simple-table>
                    <div style="background: url('https://svr02.kencana.org/viva-app/images/kmb-watermark.png') center no-repeat; background-size: contain; height: auto;">
                        <table width="100%" class="table table-bordered table-hover nowrap" style="font-size: 10px; border: 1px solid #000;">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Description</th>
                                    <th>Length</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in itemsPrintLists" :key="item.item_num">
                                    <td>{{ item.item_num}}</td>
                                    <td>{{item.NamaBarang}} {{item.brand_id}} T:{{item.tebal}} {{item.add_id ? 'add_id:'+item.add_id : ''}} {{item.tor_id ? 'add_id:'+item.tor_id : ''}}</td>
                                    <td>{{item.panjang}}</td>
                                    <td>{{item.lembar}}</td>
                                    <td>{{ $store.getters.convertToCurrencyUs(item.set_price / 1.11) }}</td>
                                    <td>{{ $store.getters.convertToCurrencyUs(item.total_price / 1.11) }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="2">SUBTOTAL : <br />  TAX 11% : <br /> TOTAL ORDER : <br /> DOWN PAYMENT (DP) : <br /> TOTAL PAYMENT : </td>
                                    <td colspan="6" style="text-align: right !important;">Rp. {{ $store.getters.convertToCurrencyUs(printSubTotalModel / 1.11) }} <br /> Rp. {{ $store.getters.convertToCurrencyUs((printSubTotalModel * 0.11) / 1.11) }}<br /> Rp. {{ $store.getters.convertToCurrencyUs((printSubTotalModel / 1.11) + (printSubTotalModel * 0.11) / 1.11 ) }} <br /> Rp. {{ $store.getters.convertToCurrencyUs(printDownPaymentModel) }} <br /> <i><b>Rp. {{ $store.getters.convertToCurrencyUs( ((printSubTotalModel / 1.11) + (printSubTotalModel * 0.11) / 1.11) - printDownPaymentModel )  }} <br /> "{{ totalTerbilang ? totalTerbilang : '' }} Rupiah"</b></i></td>
                                </tr>
                                <tr>
                                    <td colspan="6">Ship. Date : {{ printShipDateModel ? printShipDateModel : '-' }} <br /> Ship. Address : {{ printShipAddressModel ? printShipAddressModel : '-' }} <br /> Remark : {{ printRemarkModel ? printRemarkModel : '-' }}</td>
                                </tr>
                                <tr>
                                    <td colspan="6" style="text-align: center !important;">Pelunasan harap ditransfer ke rekening PT. Kencana Maju Bersama, Bank BCA Mojokerto :No A/C. <span class="text-danger"><b>050-588880-0</b></span> BCA MOJOKERTO selama pembayaran belum lunas, maka material milik PT. Kencana Maju Bersama</td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <table v-if="showSK == 'Y'" width="100%" class="table table-bordered table-hover nowrap" style="font-size: 10px; border: 1px solid #000;">
                                            <thead>
                                                <tr>
                                                    <th>Surat Pesanan</th>
                                                    <th>Stok Barang</th>
                                                    <th>Syarat dan kondisi pembayaran</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="pay_term == 'CASH BEFORE DELIVERY'">
                                                <tr>
                                                    <td rowspan="7">CBD</td>
                                                    <td rowspan="3">Ada</td>
                                                    <td>Pelunasan 100% saat transaksi</td>
                                                </tr>
                                                <tr>
                                                    <td>Barang dikirim/diambil maksimal 7 hr kerja.</td>
                                                </tr>
                                                <tr>
                                                    <td>Setelah barang tersedia, tapi konsumen tidak mau dikirim/ambil barang, maka biaya yang timbul akan dibebankan ke pembeli.</td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="4">Proses pesan material &amp; produksi (pengiriman &gt; 30 hari).</td>
                                                    <td>Uang muka 25% saat transaksi, sisanya 30 hari dilunasi dari tanggal pembayaran uang muka.</td>
                                                </tr>
                                                <tr>
                                                    <td>Setelah 7 hr kerja dari batas waktu tanggal pelunasan dan tidak ada pelunasan, maka transaksi dianggap batal dan uang muka hangus.</td>
                                                </tr>
                                                <tr>
                                                    <td>Barang dikirim/diambil maksimal 7 hr kerja setelah barang selesai.</td>
                                                </tr>
                                                <tr>
                                                    <td>Setelah barang tersedia, tp konsumen tdk mau dikirim/ambil barang, maka biaya yg timbul akan dibebankan ke pembeli.</td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td rowspan="9">CREDIT</td>
                                                    <td rowspan="2">Ada<br></td>
                                                    <td >Barang dikirim/diambil maksimal 7 hr kerja.</td>
                                                </tr>
                                                <tr>
                                                    <td>Jika lebih dari 7 hr kerja, maka biaya yg timbul akan dibebankan ke pembeli.</td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="3">Proses pesan material &amp; produksi (pengiriman &lt; 30 hari)</td>
                                                    <td>Uang muka 25% saat transaksi</td>
                                                </tr>
                                                <tr>
                                                    <td>Setelah 45 hr kerja dari awal transaksi, tp konsumen tdk mau dikirim/diambil barangnya, maka transaksi dianggap batal dan uang muka hangus.</td>
                                                </tr>
                                                <tr>
                                                    <td>Setelah barang tersedia, tp konsumen tidak mau dikirim/diambil barangnya, maka transaksi dianggap batal dan uang muka hangus.</td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="4">Proses pesan material &amp; produksi (pengiriman &gt; 30 hari)</td>
                                                    <td>Uang muka 25% saat transaksi, sisanya dilunasi 30 hr dari tanggal uang muka.</td>
                                                </tr>
                                                <tr>
                                                    <td>Setelah 7 hr kerja dari batas waktu tanggal pelunasan dan tidak ada pelunasan, maka transaksi dianggap batal dan uang muka hangus.</td>
                                                </tr>
                                                <tr>
                                                    <td>Barang dikirim/diambil maksimal 7 hr kerja setelah barang selesai.</td>
                                                </tr>
                                                <tr>
                                                    <td>Setelah barang tersedia, tp konsumen tdk mau dikirim/ambil barang, maka biaya yang timbul akan dibebankan ke pembeli.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

import Vue from 'vue';
import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Blank',
                disabled: false,
                href: '#',
                },
                {
                text: 'Blank',
                disabled: true,
                href: '#',
                },
            ],
            bookIdModel: '',
            itemsPrintLists: [],
            printTanggalModel: '',
            printCustomerModel: '',
            printSalesModel: '',
            printNIKModel: '',
            printAlamatModel: '',
            printPembayaranModel: '',
            printPengirimanModel: '',
            printSubTotalModel: '',
            printDownPaymentModel: '',
            printPajakModel: '',
            printTotalModel: '',
            printShipDateModel: '',
            printShipAddressModel: '',
            printRemarkModel: '',
            totalTerbilang: '',
            showSK: '',
            pay_term: ''
        }
    },
    async mounted(){
        
        this.initialize()
        console.log(this.$route.params.book_id)

    },
    methods:{

        initialize(){

            this.$store.dispatch('setOverlay', true)

            this.bookIdModel = this.$route.params.book_id

            axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderList/printSP`, { 
                    book_id : this.bookIdModel
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                
                console.log(res.data);
                this.itemsPrintLists = res.data.orderDtl
                this.printTanggalModel = res.data.orderHdr['tr_date']
                this.printCustomerModel = res.data.cust['NamaCustomer']
                this.printSalesModel = res.data.orderHdr['NamaSales']
                this.printNIKModel = res.data.orderHdr['nik']
                this.printAlamatModel = res.data.cust['Alamat']
                this.printPembayaranModel = res.data.orderHdr['pay_term']
                this.printPengirimanModel = res.data.orderHdr['ship_term']
                this.printPajakModel = res.data.tax
                this.printSubTotalModel = res.data.subTotal
                this.printTotalModel = res.data.total
                this.totalTerbilang = res.data.totalTerbilang
                this.printShipDateModel = res.data.orderHdr['tglkirim']
                this.printShipAddressModel = res.data.orderHdr['shipto']
                this.printRemarkModel = res.data.orderHdr['remark']
                this.showSK = res.data.orderHdr['show_sk']
                this.pay_term = res.data.orderHdr['pay_term']
                this.printDownPaymentModel = res.data.orderHdr['dp_amt']

                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },
        
        PrintSP(){

            // const modal = document.getElementById("divPrint")
            // const cloned = modal.cloneNode(true)
            // let section = document.getElementById("printSection")

            // if (!section) {
            //     section  = document.createElement("div")
            //     section.id = "printSection"
            //     document.body.appendChild(section)
            // }

            // section.innerHTML = "";
            // section.appendChild(cloned);
            // window.print();

            this.$htmlToPaper('divPrint');

        }

    }
    
}
</script>

<style scoped>

        .tg  {border-collapse:collapse;border-spacing:0;}
         .tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:10px;
         overflow:hidden;padding:2px 2px;word-break:normal;}
         .tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:10px;
         font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
         .tg .tg-wa1i{font-weight:bold;text-align:center;vertical-align:middle}
         .tg .tg-0lax{text-align:left;vertical-align:top}

</style>